import React from "react"
// MUI
import {CheckBox, CheckBoxOutlineBlank, Info} from "@mui/icons-material";
import {
    Box,
    ButtonBase,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Tooltip,
    Typography
} from "@mui/material";
// styles
import "./TemplateBasePanel.css"
// translation
import {useTranslation} from "react-i18next";
// misc
import {capitalizeFirstLetter} from "../../../functions/functions";

const TemplateBaseCard = (props: any) => {

    if (props.display === false) return <></>

    return (
        <ButtonBase className={props.selected ? "templateBaseCardButton selectedTemplateBaseCardButton" : "templateBaseCardButton"} onClick={props.onClick}>
            <Paper className={"templateBaseCardPaper"} elevation={props.selected ? 0 : 3}>
                <Box className={"templateBaseCardTextWrapper"}>
                    <img src={`/assets/templates/thumbnails/${props.type}/${props.name}.png`} alt={props.name} className={"templateBaseCardImage"} />
                    <Typography variant={"h6"} component={"p"}>
                        {capitalizeFirstLetter(props.name)}
                    </Typography>
                </Box>
            </Paper>
        </ButtonBase>
    )
}

export default function TemplateBasePanel(props: any) {

    const {template, setTemplate, templateDesign, setTemplateDesign, product} = props;

    const {t} = useTranslation(["templateEditor", "common"])

    const renderBaseSelector = () => {

        const bases = [
            {name: "service", value: "service", display: product},
            {name: "modern", value: "modern", display: true},
            {name: "classic", value: "classic", display: true},
            {name: "tower", value: "tower", display: true},
            {name: "minimalist", value: "minimalist", display: true},
        ]

        return (
            <>
                <Grid item xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <Typography variant={"h6"} component={"p"}>
                        {t("choose_base_template")}
                    </Typography>
                    &nbsp;
                    <Tooltip title={t("base_template_tooltip")} placement={"right"} arrow>
                        <Info fontSize={"small"} />
                    </Tooltip>
                </Grid>
                {bases && bases.map((base: any) => {
                    return (
                        <Grid item xs={12} sm={6} lg={3} key={base.value} sx={{ display: Boolean(base.display) ? "inline-flex" : "none" }}>
                            <TemplateBaseCard
                                type={product ? "product" : "business"}
                                name={base.name}
                                selected={template.base === base.value}
                                onClick={() => {
                                    setTemplate({...template, base: base.value})
                                }}
                                display={Boolean(base.display)}
                            />
                        </Grid>
                    )
                })}
            </>
        )
    }

    const renderCompositionSelector = () => {

        const compositionOptions = [
            {
                label: t("composition_allow_contact"),
                subtitle: t("composition_allow_contact_subtitle"),
                value: templateDesign.buttons.displaySendContactButton,
                handler: () => {
                    setTemplateDesign({...templateDesign, buttons: {
                            ...templateDesign.buttons, displaySendContactButton: !templateDesign.buttons.displaySendContactButton
                        }})
                },
                disabled: false
            },
            {
                label: t("composition_allow_add_me"),
                subtitle: t("composition_allow_add_me_subtitle"),
                value: templateDesign.buttons.displayAddMe,
                handler: () => {
                    setTemplateDesign({...templateDesign, buttons: {
                            ...templateDesign.buttons, displayAddMe: templateDesign.buttons.displayAddMe === null ? false : !templateDesign.buttons.displayAddMe
                        }})
                },
                disabled: product
            },
            {
                label: t("composition_allow_pp"),
                subtitle: t("composition_allow_pp_subtitle"),
                value: templateDesign.templateOption.displayProfilePicture,
                handler: () => {
                    setTemplateDesign({...templateDesign, templateOption: {
                            ...templateDesign.templateOption, displayProfilePicture: templateDesign.templateOption.displayProfilePicture === null ? false : !templateDesign.templateOption.displayProfilePicture
                        }})
                },
                disabled: false
            },
            {
                label: t("composition_allow_floating"),
                subtitle: t("composition_allow_floating_subtitle"),
                value: templateDesign.buttons.displayFloatingAddMe,
                handler: () => {
                    setTemplateDesign({...templateDesign, buttons: {
                            ...templateDesign.buttons, displayFloatingAddMe: templateDesign.buttons.displayFloatingAddMe === null ? true : !templateDesign.buttons.displayFloatingAddMe
                        }})
                },
                disabled: product,
                defaultToFalse: true
            },
            {
                label: t("composition_allow_section_links"),
                subtitle: t("composition_allow_section_links_subtitle"),
                value: templateDesign.templateOption.hideSectionLinksUrls,
                handler: () => {
                    setTemplateDesign({...templateDesign, templateOption: {
                            ...templateDesign.templateOption, hideSectionLinksUrls: templateDesign.templateOption.hideSectionLinksUrls === null ? true : !templateDesign.templateOption.hideSectionLinksUrls
                        }})
                },
                disabled: false,
                defaultToFalse: true
            }
        ]

        return (
            <>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant={"h6"} component={"p"}>
                        {t("choose_composition")}
                    </Typography>

                    <List>
                        {
                            compositionOptions.map((option: any, index: number) => (
                                <ListItemButton
                                    disabled={option.disabled}
                                    onClick={option.handler}
                                    key={index}
                                >
                                    <ListItemIcon>
                                        {
                                            option.defaultToFalse ?
                                                option.value ? <CheckBox /> : <CheckBoxOutlineBlank />
                                                :
                                                (option.value === null || option.value === undefined || option.value) ? <CheckBox /> : <CheckBoxOutlineBlank />
                                        }
                                        {/*{(option.value === null || option.value === undefined || option.value) ? <CheckBox /> : <CheckBoxOutlineBlank />}*/}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={option.label}
                                        secondary={option.subtitle}
                                    />
                                </ListItemButton>
                            ))
                        }
                    </List>
                </Grid>
            </>
        )
    }

    return (
        <>
            <Box className={"templateBaseWrapper"}>
                <Grid container justifyContent={"flex-start"} spacing={2}>
                    {renderBaseSelector()}
                    {renderCompositionSelector()}
                </Grid>
            </Box>
        </>
    )
}