import {useUser} from "../provider/UserProvider";

export default function useRestrictedFonts() {

    const {org} = useUser()

    const restrictedFonts = [
        { name: "Madera Regular", value: "Madera Regular", restriction: "c1c11550-11bc-47a1-a3bc-f849254d83b9"},
        { name: "Madera Bold", value: "Madera Bold", restriction: "c1c11550-11bc-47a1-a3bc-f849254d83b9"},
        { name: "Avenir Next", value: "Avenir Next", restriction: "918cef6e-2141-4a4e-8f89-88721dea518e"},
        { name: "Edenred", value: "Edenred", restriction: "17febeb6-ea8d-4bd0-a541-b3cc14d3f20d"},
        { name: "Ubuntu", value: "Ubuntu", restriction: "17febeb6-ea8d-4bd0-a541-b3cc14d3f20d"},
    ]

    if (!org) return null

    return restrictedFonts.filter((font: any) => font.restriction === org.organisation_id)
}