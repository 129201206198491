import React from "react"
// MUI
import {Box, Divider, Grid, Typography} from "@mui/material";
import {AttachFile, Business, Edit, Link, MeetingRoom, People, Window} from "@mui/icons-material";
// Components
import {Accordion, TextField, InteractiveAdornment} from "../../utils";
import {CustomHelpersSection} from "../../Forms";
import {LegacyCustomMediasSection, LegacySocialMediaSection} from "../../Forms";
// Translation
import {useTranslation} from "react-i18next";

// misc
import {regexEmail, regexPhoneNumber, regexValidUrl} from "../../../functions/functions";

export default function TemplateInformationsPanel(props: any) {

    const {t} = useTranslation(["templateEditor", "common"])
    const {templateData, setTemplateData, template, setTemplate, templateDesign, setTemplateDesign, usingProducts} = props
    // Accordions management
    const [accordionFocus, setAccordionFocus] = React.useState<number | boolean>(0)
    const handleAccordionFocus = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setAccordionFocus(isExpanded ? index : false)
    }

    const renderBusinessSection = () => {

        if (template.base === "service") return <></>

        return (
            <Accordion
                title={t("business_section")}
                expanded={accordionFocus === 0}
                onChange={handleAccordionFocus(0)}
                icon={<Business />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>
                            {t("business_section_intro")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label={t("name")}
                            value={templateData.company.name}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, name: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("address")}
                            value={templateData.company.address}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, address: e.target.value}})}
                            fullWidth
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("address_secondary")}
                            value={templateData.company.address2}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, address2: e.target.value}})}
                            fullWidth
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("city")}
                            value={templateData.company.city}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, city: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("postcode")}
                            value={templateData.company.postalCode}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, postalCode: e.target.value}})}
                            fullWidth
                        />
                    </Grid>


                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("state")}
                            value={templateData.company.state}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, state: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("country")}
                            value={templateData.company.country}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, country: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("phone")}
                            value={templateData.company.phone}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, phone: e.target.value}})}
                            fullWidth
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                            error={!regexPhoneNumber(templateData.company.phone)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("email")}
                            value={templateData.company.email}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, email: e.target.value}})}
                            fullWidth
                            error={!regexEmail(templateData.company.email)}
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("website")}
                            value={templateData.company.website}
                            onChange={(e: any) => setTemplateData({...templateData, company: {...templateData.company, website: e.target.value}})}
                            fullWidth
                            error={!regexValidUrl(templateData.company.website)}
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                        />
                    </Grid>

                </Grid>
            </Accordion>
        )

    }

    const renderOfficeSection = () => {

        if (template.base === "service") return <></>

        return (
            <Accordion
                title={t("office_section")}
                expanded={accordionFocus === 1}
                onChange={handleAccordionFocus(1)}
                icon={<MeetingRoom />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>
                            {t("office_section_intro")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("address")}
                            value={templateData.office.address}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, address: e.target.value}})}
                            fullWidth
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("address_secondary")}
                            value={templateData.office.address2}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, address2: e.target.value}})}
                            fullWidth
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("city")}
                            value={templateData.office.city}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, city: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("postcode")}
                            value={templateData.office.postCode}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, postCode: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("state")}
                            value={templateData.office.state}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, state: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("country")}
                            value={templateData.office.country}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, country: e.target.value}})}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("phone")}
                            value={templateData.office.phone}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, phone: e.target.value}})}
                            fullWidth
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                            error={!regexPhoneNumber(templateData.office.phone)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("email")}
                            value={templateData.office.email}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, email: e.target.value}})}
                            fullWidth
                            error={!regexEmail(templateData.office.email)}
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label={t("website")}
                            value={templateData.office.website}
                            onChange={(e: any) => setTemplateData({...templateData, office: {...templateData.office, website: e.target.value}})}
                            fullWidth
                            InputProps={{
                                endAdornment: <InteractiveAdornment />
                            }}
                            error={!regexValidUrl(templateData.office.website)}
                        />
                    </Grid>

                </Grid>
            </Accordion>
        )

    }

    const renderCustomPersonsSection = () => {
        return (
            <Accordion
                title={usingProducts ? t("sections_section") : t("office_helpers_section")}
                icon={usingProducts ? <Window /> : <People />}
                expanded={accordionFocus === 2}
                onChange={handleAccordionFocus(2)}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>
                            {usingProducts ? t("sections_section_intro") : t("helpers_section_intro")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <CustomHelpersSection
                            data={templateData}
                            setData={setTemplateData}
                            {...{usingProducts}}
                        />
                    </Grid>
                </Grid>

            </Accordion>
        )

    }

    const renderMediaSection = () => {
        return (
            <Accordion
                title={t("media_section")}
                expanded={accordionFocus === 3}
                onChange={handleAccordionFocus(3)}
                icon={<AttachFile />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>

                    <Grid item xs={12}>
                        <Typography variant={"h6"} component={"p"} color={"secondary"} gutterBottom>
                            {t("embedded_youtube_video")}
                        </Typography>

                        <Typography variant={"body2"}>
                            {t("embedded_youtube_video_intro")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={t("video_url")}
                            error={!regexValidUrl(template.information.medias.youtubeUrl)}
                            helperText={t("video_does_not_appear_on_preview")}
                            value={template.information.medias.youtubeUrl}
                            onChange={(e: any) =>
                                setTemplate({...template, information: {...template.information, medias: {...template.information.medias, youtubeUrl: e.target.value}}})}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <LegacyCustomMediasSection
                            data={templateData}
                            setData={setTemplateData}
                        />
                    </Grid>
                </Grid>
            </Accordion>
        )

    }

    const renderSocialMediaSection = () => {
        return (
            <Accordion
                title={t("social_section")}
                expanded={accordionFocus === 4}
                onChange={handleAccordionFocus(4)}
                icon={<Link />}
            >
                <LegacySocialMediaSection
                    data={templateData}
                    setData={setTemplateData}
                />
            </Accordion>
        )

    }

    const renderLabellingSection = () => {
        return (
            <Accordion
                title={t("labelling_section")}
                expanded={accordionFocus === 5}
                onChange={handleAccordionFocus(5)}
                icon={<Edit />}
            >
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>
                            {t("labelling_section_intro")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("biography")}
                            value={templateDesign?.templateOption?.biographyTitle}
                            onChange={(e: any) => setTemplateDesign({
                                ...templateDesign,
                                templateOption: {
                                    ...templateDesign?.templateOption,
                                    biographyTitle: e.target.value
                                }
                            })}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Accordion>
        )
    }

    return (
        <>
            <Box className={"designBaseWrapper"}>
                {renderBusinessSection()}
                {renderOfficeSection()}
                {renderCustomPersonsSection()}
                {renderMediaSection()}
                {renderSocialMediaSection()}
                {renderLabellingSection()}
            </Box>
        </>
    )
}