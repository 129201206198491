import {useEffect} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
// Globals CSS
import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
// Pages
import {ContactEditor, VcardEditor, Main, TemplateEditor, Error} from "./views"
// Provider
import {UserProvider} from "./provider/UserProvider";
// Translations
import i18n from "i18next";
// Token
import {APIProvider} from "./provider/APIProvider";
import {ModuleProvider} from "./provider/ClientModuleProvider";
import UIProvider from "./provider/UIProvider";
import {TourProvider} from "./provider/TourProvider";
import IframeManager from "./provider/IframeManager";
import {PermissionsProvider} from "./provider/PermissionsProvider";
import {ErrorBoundary} from "react-error-boundary";
import CriticalError from "./views/Error/CriticalError";

const {REACT_APP_DASHBOARD_URL} = process.env

function App() {


    const resizeObserver = new ResizeObserver(async (entries) => {
        let detectedHeight = entries[0].target.clientHeight
        resize(detectedHeight)
    })

    resizeObserver.observe(document.body)

    const resize = (size: number) => {
        let _size = size < 800 ? 800 : size
        let payload = JSON.stringify(["resize", _size])
        window.top?.postMessage(payload, `${REACT_APP_DASHBOARD_URL}`)
    }

    const query = new URLSearchParams(window.location.search)

    useEffect(() => {
        // Get locale query
        if (query) {
            let _lng = query.get("lng")

            if (_lng && _lng.includes("fr")) {
                i18n.changeLanguage("fr").then()
            } else if (_lng && _lng.includes("en")) {
                i18n.changeLanguage("en").then()
            }
        }
    }, [query])




    return (
        <ErrorBoundary FallbackComponent={CriticalError}>
            <APIProvider>
                <UserProvider>
                    <ModuleProvider>
                        <PermissionsProvider>
                            <TourProvider>
                                <UIProvider>
                                    <BrowserRouter>
                                        <IframeManager>
                                            <Routes>
                                                <Route path="/" element={<Main />} />

                                                <Route path={"/template/create"} element={<TemplateEditor />} />
                                                <Route path={"/template/edit"} element={<TemplateEditor />} />

                                                <Route path={"/vcard/create"} element={<VcardEditor />} />
                                                <Route path={"/vcard/edit"} element={<VcardEditor />} />

                                                <Route path={"/contact/edit"} element={<ContactEditor />} />

                                                <Route path={"*"} element={<Error />} />
                                            </Routes>
                                        </IframeManager>
                                    </BrowserRouter>
                                </UIProvider>
                            </TourProvider>
                        </PermissionsProvider>
                    </ModuleProvider>
                </UserProvider>
            </APIProvider>
        </ErrorBoundary>
    );
}

export default App;
