import React from "react"
// mui
import {Paper} from "@mui/material";
// components
import {SectionAction, SectionCollapse} from "../../index";

export default function MediasSection(props: any) {

    const {styles, t, templateData, template, vcardData} = props;

    let mediasSections: any = []

    if (templateData.information.medias.custom && templateData.information.medias.custom.length > 0) {
        mediasSections = templateData.information.medias.custom
    }

    if (vcardData.information.medias.custom && vcardData.information.medias.custom.length > 0) {
        mediasSections = mediasSections.concat(vcardData.information.medias.custom)
    }

    if (mediasSections) {
        let groupedMediasSections: any = {}

        mediasSections.map((media: any) => {
            if (media.mediaGroupName) {
                if (!groupedMediasSections[media.mediaGroupName]) {
                    groupedMediasSections[media.mediaGroupName] = []
                }
                if (groupedMediasSections[media.mediaGroupName] !== undefined) {
                    groupedMediasSections[media.mediaGroupName].push(media)
                }
            } else if (media.title) {
                if (!groupedMediasSections[media.title]) {
                    groupedMediasSections[media.title] = []
                }
                if (groupedMediasSections[media.title] !== undefined) {
                    groupedMediasSections[media.title].push(media)
                }
            }
        })

        let results: any = []

        Object.keys(groupedMediasSections).forEach((key, index) => {
            const medias = groupedMediasSections[key]
            let _medias = medias.map((media: any, indexInner: number) => {
                if (media.fileName) {
                    return <SectionAction
                        key={index+"_"+indexInner}
                        styles={styles}
                        dataName={media.title ? media.title : t("vcard_media")}
                        dataContent={" "}
                        action={() => {return}}
                    />
                } else if (media.customUrl) {
                    return <SectionAction
                        key={index+"_"+indexInner}
                        styles={styles}
                        dataName={media.title ? media.title : t("vcard_media")}
                        action={() => {return}}
                        forceDisplay={template?.templateOption?.hideSectionLinksUrls}
                        dataContent={
                            (media.customUrl && !template?.templateOption?.hideSectionLinksUrls) ? media.customUrl.substring(0,22) + "..."
                                : media.fileName ? media.fileName.substring(0,22) + "..."
                                    : ""}
                    />
                }
            })

            results.push(
                <Paper sx={styles.paper} key={index}>
                    <SectionCollapse
                        type={"media"}
                        plain
                        {...{styles, t}}
                        title={key || t("vcard_media_section")}
                    >
                        {_medias}
                    </SectionCollapse>
                </Paper>
            )
        })

        if (groupedMediasSections && results) return <>{results}</>
    }

    return null
}
